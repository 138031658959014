import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import CompanyLogo from 'ui/elements/CompanyLogo';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { IEntity } from 'types/company';
import Button from 'ui/elements/buttons/Button';

import styles from './styles.scss';
import { Link } from 'react-router-dom';
import { communityUrls, companyUrls, organizationUrls } from 'urls';

interface Props {
  companies: IEntity[];
  context: 'community' | 'company' | 'organization';
  title: string;
  onClick: () => void;
}
export default function CompanyList(props: Props) {
  const limit = 8;
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setShowAll(false);
  }, [props.companies]);

  return props.companies.length > 0 ? (
    <div className="u-section-spacing-top">
      <span className={styles.categoryTitle}>{props.title}</span>
      <div className="u-flex u-flex--wrap u-flex--column u-content-spacing-y">
        {props.companies.slice(0, showAll ? undefined : limit).map(c => (
          <Link
            key={c.id}
            className={styles.companyItem}
            to={
              props.context === 'community'
                ? communityUrls.overview(c.slug)
                : props.context === 'company'
                  ? companyUrls.overview(c.slug, 'updates')
                  : organizationUrls.view(c.slug)
            }
            onClick={props.onClick}
          >
            <div className={styles.logoAndText}>
              <CompanyLogo company={c} />
              <span className={styles.companyItemName}>{c.name}</span>
            </div>
            <ChevronRightIcon />
          </Link>
        ))}
      </div>
      {!showAll && props.companies.length > limit && (
        <Button color="white" kind="tertiary" className={cx(styles.showMore)} onClick={() => setShowAll(true)}>
          Show {props.companies.length - limit} more
        </Button>
      )}
    </div>
  ) : null;
}
