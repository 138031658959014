import { OnboardingPart, OnboardingStep, Step } from 'apis/OnboardingAPI';
import { updateInList } from 'util/arrayUtils';
import { communityUrls, companyUrls } from 'urls';
import { logError } from 'util/logging';
import { ICompany } from 'types/company';

export function getChecklistStepText(stepId: OnboardingStep) {
  switch (stepId) {
    case 'CompanyInfo':
      return 'Add general company information';
    case 'IndustriesAndStage':
      return 'Add industry and stage';
    case 'CompanyMission':
      return 'Add company mission';
    case 'Kpis':
      return 'Add your KPIs';
    case 'CompanyBranding':
      return 'Upload company logo and banner image';
    case 'ProblemSolutionUnfairAdvantage':
      return 'Add executive summary';
    case 'PitchDeck':
      return 'Upload your pitch deck';
    case 'InviteYourStakeholders':
      return 'Invite shareholders';
    case 'PostFirstUpdate':
      return 'Post first company update';
    case 'InvestmentPreferences':
      return 'Add investment preferences';
    case 'InvestorType':
      return 'Add investor type';
    case 'InvestmentRange':
      return 'Add preferred range & capacity';
    case 'CommunityBranding':
      return 'Upload community logo';
    case 'CommunityDescription':
      return 'Write a description of your community';
    case 'InviteYourTeam':
      return 'Invite your team';
    case 'InviteCompanies':
      return 'Invite companies';
    case 'InviteInvestorsAndMentors':
      return 'Invite investors and mentors';
    case 'ChooseYourRole':
      return 'Choose your role';
    case 'UserProfile':
      return 'Add user information';
    case 'BasicProfile':
      return 'Complete your basic profile';
    case 'IndustryAndBusinessFocus':
      return 'Add your industry and business focus';
    case 'ProductAndFundingStage':
      return 'Add your product and funding stage';
    case 'OrganizationType':
      return 'Add your organization type';
    case 'OrganizationIndustries':
      return 'Add your organization industries';
    case 'Services':
      return 'Add your services';
    case 'OrganizationProfile':
      return 'Complete your organization profile';
    case 'InviteOrganizationMembers':
      return 'Invite your team';
  }
}

export const setStepCompleted = (prev: OnboardingPart, step: OnboardingStep) => ({
  ...prev,
  steps: updateInList(
    prev.steps,
    item => item.step === step,
    () => ({ step, completenessPercentage: 100, isCompleted: true }),
  ),
});

export function goToOnboardingStep(
  step: OnboardingStep,
  openStep: (step: OnboardingStep) => void,
  push: (href: string) => void,
  company?: ICompany,
  communitySlug?: string,
) {
  switch (step) {
    case 'CompanyInfo':
      return company
        ? () => openStep('CompanyInfo')
        : () => logError(`go to onboarding step ${step} failed, companyId is missing`);
    case 'CommunityDescription':
      return () =>
        communitySlug
          ? push(communityUrls.settings.information(communitySlug, 'general-information'))
          : logError(`go to onboarding step ${step} failed, communityId is missing`);
    case 'Kpis':
      return () => openStep('Kpis');
    case 'CompanyBranding':
      return company
        ? () => openStep('CompanyBranding')
        : () => logError(`go to onboarding step ${step} failed, companyId is missing`);
    case 'InviteYourStakeholders':
      return () =>
        company
          ? push(companyUrls.settings.inviteUser(company.slug))
          : logError(`go to onboarding step ${step} failed, companyId is missing`);
    case 'InviteYourTeam':
      return () =>
        communitySlug
          ? push(communityUrls.manageMembers.invite(communitySlug, 'Employee'))
          : logError(`go to onboarding step ${step} failed, communityId is missing`);
    case 'InviteInvestorsAndMentors':
      return () =>
        communitySlug
          ? push(communityUrls.manageMembers.invite(communitySlug, 'Investor'))
          : logError(`go to onboarding step ${step} failed, communityId is missing`);
    case 'InviteCompanies':
      return () =>
        communitySlug
          ? push(communityUrls.manageMembers.invite(communitySlug, 'Company'))
          : logError(`go to onboarding step ${step} failed, communityId is missing`);
    case 'PostFirstUpdate':
      return () =>
        company
          ? push(companyUrls.overview(company.slug, 'updates', { isPostingUpdate: true }))
          : logError(`go to onboarding step ${step} failed, companyId is missing`);
    case 'InvestmentPreferences':
    case 'InvestmentRange':
    case 'InvestorType':
    case 'CompanyMission':
    case 'IndustriesAndStage':
    case 'PitchDeck':
    case 'UserProfile':
    case 'CommunityBranding':
    case 'ProblemSolutionUnfairAdvantage':
      return () => openStep(step);
  }
  return undefined;
}

export function calculateCompletedPercentage(onboardingSteps: Step[]) {
  return Math.ceil(
    onboardingSteps.map(it => it.completenessPercentage).reduce((a, b) => a + b, 0) / onboardingSteps.length,
  );
}
