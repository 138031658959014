import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import React from 'react';
import Card, { BackgroundColor } from 'ui/views/cards/Card';
import cx from 'classnames';
import styled from '@emotion/styled';
import styles from './cards.scss';
import { Link } from 'react-router-dom';

interface Props {
  title?: React.ReactNode;
  avatar?: React.ReactNode;
  padding?: 'default' | 'half' | 'none';
  isRounded?: boolean;
  elevation?: number;
  className?: string;
  actions?: Children;
  onClick?: () => void;
  href?: string;
  color?: BackgroundColor;
  disableIcon?: boolean;
  children?: Children;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

function ClickableElement({
  className,
  href,
  children,
  onClick,
  style,
}: {
  className?: string;
  href?: string;
  onClick?: () => void;
  children: Children;
  style?: React.CSSProperties;
}) {
  return href ? (
    <Link className={className} to={href} style={style}>
      {children}
    </Link>
  ) : onClick ? (
    <button onClick={onClick} className={className} style={style}>
      {children}
    </button>
  ) : (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export default function ClickableCard(props: Props) {
  const padding = {
    [styles.paddingDefault]: props.padding === 'default' || props.padding === undefined,
    [styles.paddingHalf]: props.padding === 'half',
  };
  return (
    <Card
      color={props.color}
      padding="none"
      className={cx('u-flex u-flex-space-between u-flex-align-center', props.className, {
        [styles.hoverGrey]: props.onClick != null || props.href != null,
        [styles.rounded]: props.isRounded,
      })}
      elevation={props.elevation}
      style={props.style}
    >
      <ClickableElement
        onClick={props.onClick}
        href={props.href}
        className={cx('u-flex u-flex--row u-flex--align-items-center u-flex-1', padding)}
        style={{ cursor: props.style?.cursor }}
      >
        {props.avatar}
        <Content
          style={{ width: '100%' }}
          className={cx({
            'u-content-spacing-left': props.avatar,
          })}
        >
          <span className="text-weight-medium text-medium">{props.title}</span>
          {props.children}
        </Content>
        {!props.actions && !props.disableIcon && (props.onClick || props.href) && (
          <div className="u-content-padding-left">
            {props.icon ? props.icon : <ChevronRightIcon color="grey" strokeWidth={2} />}
          </div>
        )}
      </ClickableElement>
      {props.actions && (
        <div
          className={cx('u-flex u-flex--row u-flex--align-items-center', {
            [styles.paddingDefault]: props.padding === 'default' || props.padding === undefined,
            [styles.paddingHalf]: props.padding === 'half',
          })}
        >
          {props.actions}
        </div>
      )}
    </Card>
  );
}
