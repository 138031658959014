import React from 'react';
import { communityUrls, companyUrls } from 'urls';
import styles from '../styles.scss';
import Logo from 'ui/domain/Logo';
import { ICompany } from 'types/company';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export function CompanyOrCommunityLogoMobile({
  companyOrCommunity,
  routeContext: companyOrCommunityContext,
  onClick,
}: {
  routeContext: 'company' | 'community';
  companyOrCommunity: ICompany;
  onClick: (url: string) => void;
}) {
  return (
    companyOrCommunity && (
      <>
        <Divider />
        <ListItem
          onClick={() =>
            onClick(
              companyOrCommunityContext === 'community'
                ? communityUrls.overview(companyOrCommunity.slug)
                : companyUrls.overview(companyOrCommunity.slug, 'profile'),
            )
          }
          style={{ marginLeft: -9 }}
        >
          <ListItemIcon>
            <div className={styles.companyLogoWrapper}>
              <Logo url={companyOrCommunity.logoURL} type={companyOrCommunityContext} size={38} />
            </div>
          </ListItemIcon>
          <ListItemText style={{ paddingLeft: '9px' }}>
            <h3 className="text-h3">{companyOrCommunity.name}</h3>
          </ListItemText>
        </ListItem>
        <Divider />
      </>
    )
  );
}
