import { FormControl, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import ControlledTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import { staticFileLocations } from 'urls';
import { capitalize } from 'util/stringUtils';
import * as yup from 'yup';
import { NewCompanyDTO, NewOrganizationDTO } from './actions';

const newCompanySchema = yup.object().shape({
  companyName: yup.string(),
  organizationName: yup.string(),
  acceptedTerms: yup
    .boolean()
    .oneOf(
      [true],
      'In order to use CrowdWorks we kindly ask you to read and accept our terms of use and privacy policy.',
    ),
});

export default function CompanyRegistrationForm({
  onRegisterNew,
  registerAs,
  isSaving,
  submitButtonText,
  autoFocus,
}: {
  onRegisterNew: (values: NewCompanyDTO | NewOrganizationDTO) => void;
  registerAs: 'company' | 'community' | 'organization';
  isSaving: boolean;
  submitButtonText?: string;
  autoFocus: boolean;
}) {
  const formik = useFormik({
    initialValues: {
      companyName: '',
      acceptedTerms: false,
    },
    onSubmit: onRegisterNew,
    validationSchema: newCompanySchema,
    validateOnChange: true,
  });

  return (
    <>
      <FormRow>
        {registerAs === 'company' || registerAs === 'community' ? (
          <ControlledTextField
            name="companyName"
            label={`${capitalize(registerAs)} name`}
            formikProps={formik}
            placeholder="Kramerica industries"
            autoFocus={autoFocus}
            autoComplete="organization"
          />
        ) : (
          <ControlledTextField
            name="organizationName"
            label={`${capitalize(registerAs)} name`}
            formikProps={formik}
            placeholder="Kramerica industries"
            autoFocus={autoFocus}
            autoComplete="organization"
          />
        )}
      </FormRow>
      <FormRow>
        <FormControl required error={!!formik.errors.acceptedTerms && !!formik.touched.acceptedTerms}>
          <Checkbox
            name="acceptedTerms"
            onChange={formik.handleChange}
            checked={!!formik.values.acceptedTerms}
            color="primary"
            fontSize="small"
            label={
              <span>
                I agree to the{' '}
                <a
                  className="text-link"
                  href={staticFileLocations.termsOfAgreements}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  terms and conditions
                </a>{' '}
                and the&nbsp;
                <a
                  href={staticFileLocations.dataProcessingAgreement}
                  className="text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  data processing agreement
                </a>
              </span>
            }
          />
          <FormHelperText>{formik.errors.acceptedTerms ?? ' '}</FormHelperText>
        </FormControl>
      </FormRow>
      <div className="u-content-spacing-top">
        <Button kind="primary" isLoading={isSaving} onClick={formik.submitForm}>
          {submitButtonText || `Create ${registerAs}`}
        </Button>
      </div>
    </>
  );
}
