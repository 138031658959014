import React from 'react';
import Button, { ButtonProps } from 'ui/elements/buttons/Button';
import CopyIcon from '../icons/CopyIcon';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

interface Props {
  copyString: string;
  displayString: string;
  kind?: ButtonProps['kind'];
  minWidth?: number;
  minHeight?: number;
  color?: ButtonProps['color'];
  disabled?: boolean;
}

export default function CopyButton(props: Props) {
  const buttonKind = props.kind || 'primary';
  const minWidth = props.minWidth || 100;
  const buttonColor = props.color || 'blue';

  const { copy, showCopiedSuccess } = useCopyToClipboard(props.copyString);

  return (
    <Button
      onClick={() => copy(props.copyString)}
      kind={buttonKind}
      color={showCopiedSuccess ? 'green' : buttonColor}
      style={{ minWidth: minWidth, minHeight: props.minHeight }}
      disabled={props.disabled}
      className="text-nowrap"
    >
      {showCopiedSuccess ? (
        <CheckmarkIcon className="u-quarter-padding-right" fontSize="medium" />
      ) : (
        <CopyIcon className="u-quarter-padding-right" fontSize="medium" />
      )}
      <p>{showCopiedSuccess ? 'Copied!' : props.displayString}</p>
    </Button>
  );
}
