import React, { useState, useEffect } from 'react';
import { WizardType } from 'pages/Dashboard/Dashboard';
import CompanyOnboardingWizard from 'domain/onboarding/company/Wizard/CompanyWizard';
import {
  OnboardingAPI,
  onboardingApiUrls,
  Onboarding as OnboardingDTO,
  OnboardingPart,
  OnboardingStep,
  OnboardingWizardStatus,
} from 'apis/OnboardingAPI';
import { setStepCompleted } from 'domain/onboarding/utils';
import Dialog from 'ui/views/dialogs/Dialog';
import useRoute from 'hooks/useRoute';
import { companyUrls, organizationUrls } from 'urls';
import UserOnboardingWizard from 'domain/onboarding/investor/UserOnboardingWizard';
import { SelfUserProfile } from 'types/user';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import useResource from 'util/resource/useResource';
import useLazyResource from 'util/resource/useLazyResource';
import { ICompany } from 'types/company';
import { invalidate } from 'hooks/useSWR';
import { onboardinWizardStatusKey } from './InitialLoadWrapper';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import OrganizationWizard from 'domain/onboarding/organization/OrganizationWizard';
import { TinyOrganizationDTO } from 'types/organization';
import { getOrUndefined } from 'util/resource';
import { CommunityInviteDetails } from 'types/company/community';
import { useLoginState } from 'auth/useLoginWithRedirect';
import { useCommunityInvite } from 'apis/CompanyAPI/communities/useCommunityInvite';

export default function OnboardingWrapper({
  onboardingStatus,
  children,
}: {
  onboardingStatus: OnboardingWizardStatus;
  children: React.ReactNode;
}) {
  const { resource: userResource } = useSelfUserProfile();

  const { loginState } = useLoginState();
  const { resource: communityInviteResource } = useCommunityInvite(loginState?.joinCommunity?.code);
  const { resource: onboardingResource } = useResource<OnboardingDTO>(
    onboardingStatus.organization
      ? onboardingApiUrls.getOrganizationOnboarding(onboardingStatus.organization.slug)
      : onboardingStatus.company
        ? onboardingApiUrls.getCompanyOnboarding(onboardingStatus.company.slug)
        : onboardingApiUrls.getUserOnboarding,
  );

  return (
    <Resources resources={[userResource, onboardingResource]} renderLoading="Bar">
      {([user, onboarding]) => (
        <Onboarding
          onboarding={onboarding}
          userProfile={user}
          onboardingStatus={onboardingStatus}
          communityInvite={getOrUndefined(communityInviteResource)}
        >
          {children}
        </Onboarding>
      )}
    </Resources>
  );
}

function Onboarding({
  userProfile,
  onboarding,
  onboardingStatus,
  communityInvite,
  children,
}: {
  onboarding: OnboardingDTO;
  userProfile: SelfUserProfile;
  onboardingStatus: OnboardingWizardStatus;
  communityInvite?: CommunityInviteDetails;
  children: React.ReactNode;
}) {
  const [patchUserOnboarding] = useLazyResource((status: 'Skipped' | 'Completed') =>
    OnboardingAPI.patchUserOnboarding(status),
  );

  const [patchCompanyOnboarding] = useLazyResource(
    ({ company, status }: { company: ICompany; status: 'Skipped' | 'Completed' }) =>
      OnboardingAPI.patchCompanyOnboarding(company.slug, status),
  );

  const [patchOrganizationOnboarding] = useLazyResource(
    ({ organization, status }: { organization: TinyOrganizationDTO; status: 'Skipped' | 'Completed' }) =>
      OnboardingAPI.patchOrganizationOnboarding(organization.slug, status),
  );

  const getWizardType = (): WizardType => {
    if (onboardingStatus.organization) {
      return { type: 'organization', organization: onboardingStatus.organization };
    } else if (onboardingStatus.company) {
      return { type: 'company', company: onboardingStatus.company };
    } else if (!onboardingStatus.company) {
      return { type: 'user' };
    }
    return {
      type: 'none',
    };
  };

  const [wizardType, setWizardType] = useState<WizardType>(getWizardType());
  const [onboardingState, setOnboardingState] = useState<OnboardingPart>(onboarding.wizard);

  // In the user onboarding, if the user chooses to register a company
  // we need to switch from the user onboarding to the company onboarding
  useEffect(() => {
    setWizardType(getWizardType());
  }, [onboardingStatus]);

  const setWizardStepCompleted = (step: OnboardingStep) => setOnboardingState(setStepCompleted(onboardingState, step));
  const { push } = useRoute();
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  if (wizardType.type !== 'none') {
    return (
      <Dialog fullScreen={isMobile} verticalAlign="top" maxWidth="md" open backdropColor="solid">
        {wizardType.type === 'organization' && (
          <OrganizationWizard
            organizationSlug={wizardType.organization.slug}
            wizard={onboardingState}
            setStepCompleted={setWizardStepCompleted}
            onClose={status => {
              invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
              invalidate(onboardingApiUrls.getOrganizationOnboarding(wizardType.organization.slug));
              patchOrganizationOnboarding({ organization: wizardType.organization, status }).finally(() => {
                // if we change url before the api call is done, react crashes with a suspend error 🙃
                push(organizationUrls.view(wizardType.organization.slug));
              });
            }}
          />
        )}
        {wizardType.type === 'company' && (
          <CompanyOnboardingWizard
            onClose={status => {
              invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
              invalidate(onboardingApiUrls.getCompanyOnboarding(wizardType.company.slug));
              patchCompanyOnboarding({ company: wizardType.company, status }).finally(() => {
                // if we change url before the api call is done, react crashes with a suspend error 🙃
                push(companyUrls.overview(wizardType.company.slug, 'profile'));
              });
            }}
            company={wizardType.company}
            wizard={onboardingState}
            setStepCompleted={setWizardStepCompleted}
          />
        )}
        {wizardType.type === 'user' && (
          <UserOnboardingWizard
            invite={communityInvite}
            onShowWizard={wizard => setWizardType(wizard)}
            user={userProfile}
            onboarding={onboardingState}
            onClose={() => {
              setWizardType({ type: 'none' });
              patchUserOnboarding('Skipped').then(() => invalidate(onboardingApiUrls.getStatus));
            }}
            onWizardCompleted={() => {
              setWizardType({ type: 'none' });
              patchUserOnboarding('Completed').then(() => {
                invalidate(onboardingApiUrls.getStatus);
                invalidate(onboardingApiUrls.getUserOnboarding);
              });
            }}
          />
        )}
      </Dialog>
    );
  }

  return children;
}
