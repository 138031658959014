import React from 'react';

import PlusIcon from 'ui/elements/icons/PlusIcon';
import Button from 'ui/elements/buttons/Button';
import { Divider, Fab, ListItemText, Menu, MenuItem as MuiMenuItem, useMediaQuery } from '@mui/material';
import UserIcon from 'ui/elements/icons/UserIcon';
import BriefcaseIcon from 'ui/elements/icons/BriefcaseIcon';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import UserAccountIcon from 'ui/elements/icons/UserAccountIcon';
import NeuralNetworkIcon from 'ui/elements/icons/NeuralNetworkIcon';
import HandshakeIcon from 'ui/elements/icons/HandshakeIcon';
import CardsIcon from 'ui/elements/icons/CardsIcon';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import ContactMeButton from 'domain/users/ContactMeButton';
import { FeedbackContext, SelfUserProfile } from 'types/user';
import { getOrUndefined } from 'util/resource';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import CopyButton from 'ui/elements/CopyButton';
import AnchorAsButton from 'ui/elements/buttons/AnchorAsButton';
import EnvelopeIcon from 'ui/elements/icons/EnvelopeIcon';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { userUrls } from 'urls';
import config from 'config';
import { bluePlanetTheme } from 'ui/theme';
import CompanyIcon from 'ui/elements/icons/CompanyIcon';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useNotify from 'hooks/useNotify';
import { useCloseableMessage } from 'apis/CompanyAPI/users/useUiState';
import { useOrganizations } from 'apis/OrganizationAPI/organizations/useOrganizations';

export const inviteUserMailtoString = (
  utm_medium: string,
  userEmail?: string,
) => `mailto:?${userEmail && `from=${userEmail}`}&subject=Join me on CrowdWorks!&body=Hi,
%0D%0A%0D%0A
I have been using CrowdWorks to connect with a larger network of investors, advisors and founders, and I wondered if you wanted to join me there! With a profile, you can join communities where you can browse and follow interesting startups.
%0D%0A%0D%0A
You can register your profile here: ${encodeURIComponent(`${config.PORTAL_URL}${userUrls.registration.user.register({ utm_source: 'email', utm_medium })}`)}j%0D 
%0D%0D
Best,`;

export function InviteUsers({ utm_medium }: { utm_medium: string }) {
  const { resource: userProfileResource } = useSelfUserProfile();
  const user = getOrUndefined(userProfileResource);

  return (
    <ButtonList className="u-content-spacing-top">
      <CopyButton
        copyString={`${config.PORTAL_URL}${userUrls.registration.user.register({ utm_source: 'copy-link', utm_medium })}`}
        displayString="Copy invite link"
      />
      <AnchorAsButton
        rel="noopener noreferrer"
        kind="tertiary"
        size="large"
        target="_blank"
        href={inviteUserMailtoString(utm_medium, user?.email)}
      >
        <EnvelopeIcon fontSize="small" className="u-quarter-spacing-right" />
        Invite via email
      </AnchorAsButton>
    </ButtonList>
  );
}

function MenuItem({
  primary,
  secondary,
  icon,
  onClick,
}: {
  primary: string;
  secondary?: string;
  icon: JSX.Element;
  onClick: () => void;
}) {
  return (
    <MuiMenuItem sx={{ margin: `0 ${halfSpacing}` }} onClick={onClick}>
      <IconAvatar color="blue-light" size={40} className="u-half-spacing-right">
        {icon}
      </IconAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </MuiMenuItem>
  );
}

type Product = 'network' | 'portfolio' | 'event' | 'program' | 'deal';

const getTitle = (product: Product) => {
  switch (product) {
    case 'network':
      return 'Network community';
    case 'portfolio':
      return 'Portfolio network';
    case 'event':
      return 'Event';
    case 'program':
      return 'Program applications';
    case 'deal':
      return 'Deal flow';
  }
};
const getSubHeading = (product: Product) => {
  switch (product) {
    case 'network':
      return 'Create a group for your network';
    case 'portfolio':
      return 'Create a group for your portfolio companies';
    case 'event':
      return 'Present your event to everybody';
    case 'program':
      return 'Collect applications for an accelerator program';
    case 'deal':
      return 'Collect applications for an investment deal flow';
  }
};

const getContent = (product: Product) => {
  switch (product) {
    case 'network':
      return (
        <div>
          <p>
            Unleash the power of you network of investors, partners, sponsors and founders. Can we contact you for a
            demo and quick walk through, so you get to see how CrowdWorks can help you?
          </p>
          <br />
          <p>Prices from €500 per month.</p>
        </div>
      );
    case 'portfolio':
      return (
        <div>
          <p>
            Unleash the power of you network of startups, growth companies and investors. Can we contact you for a demo
            and quick walk through, so you get to see how CrowdWorks can help you?
          </p>
          <br />
          <p>Prices from €500 per month.</p>
        </div>
      );
    case 'event':
      return (
        <div>
          <p>
            Get great reach and big audience for your events. Lets get in touch for a quick demo and walk through, so
            you get to see how CrowdWorks can help you!
          </p>
          <br />
          <p>Prices from €50 per month.</p>
        </div>
      );
    case 'program':
      return (
        <div>
          <p>
            Boost your program with more and relevant founder applicants. Lets get in touch for a quick demo and walk
            through, so you get to see how CrowdWorks can help you?
          </p>
          <br />
          <p>Prices from €500 per month.</p>
        </div>
      );
    case 'deal':
      return (
        <div>
          <p>
            Get great deal flow straight to your co-investors and investment team. Can we contact you for a demo and
            quick walk through, so you get to see how CrowdWorks can help you?
          </p>
          <br />
          <p>Prices from €500 per month.</p>
        </div>
      );
  }
};

const getFeedbackContext = (product: Product): FeedbackContext => {
  switch (product) {
    case 'network':
      return 'InterestedInNetworkCommunity';
    case 'portfolio':
      return 'InterestedInPortfolioNetwork';
    case 'event':
      return 'InterestedInPromotingEvent';
    case 'program':
      return 'InterestedInProgramApplications';
    case 'deal':
      return 'InterestedInDealFlow';
  }
};

function SelectedProductDialog({
  user,
  selectedProduct,
  onClose,
}: {
  user?: SelfUserProfile;
  selectedProduct: Product;
  onClose: () => void;
}) {
  const localStorageKey = `${user?.cwUserId}-${getFeedbackContext(selectedProduct)}`;

  const { isClosed: hasPressedInterestedButton } = useCloseableMessage(localStorageKey);

  return (
    <Dialog open onClose={onClose}>
      <Title onClose={onClose} subheading={getSubHeading(selectedProduct)}>
        {getTitle(selectedProduct)}
      </Title>
      <Content>{getContent(selectedProduct)}</Content>
      <DialogActions>
        <ContactMeButton
          tag={localStorageKey}
          feedback={getFeedbackContext(selectedProduct)}
          buttonText="Yes, I'm interested"
        />
        {!hasPressedInterestedButton && (
          <Button kind="tertiary" onClick={onClose}>
            Not now
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function RegisterCompanyDialog({ onClose }: { onClose: () => void }) {
  return (
    <Dialog open onClose={onClose}>
      <Title onClose={onClose} subheading="Create a profile for your future and existing investors">
        Startup profile page
      </Title>
      <Content>
        <div>
          <p>
            Scale faster by showing your traction. Just add your pitch information, invite your network and get airtime
            with new potential investors with every update you make.
          </p>
          <br />
          <p>Free tier. Additional services from €30 per month.</p>
        </div>
      </Content>
      <DialogActions>
        <LinkAsButton url={userUrls.registration.company({ utm_medium: 'dashboard-create', utm_source: 'app' })}>
          Create a startup profile
        </LinkAsButton>
        <Button kind="tertiary" onClick={onClose}>
          Not now
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RegisterOrganizationDialog({ onClose }: { onClose: () => void }) {
  return (
    <Dialog open onClose={onClose}>
      <Title onClose={onClose} subheading="Present your company and services to everybody">
        Organization profile page
      </Title>
      <Content>
        <div>
          <p>Expand the audience reach for your team, services, events and perks.</p>
          <br />
          <p>Free tier. Additional services from €50 per month.</p>
        </div>
      </Content>
      <DialogActions>
        <LinkAsButton url={userUrls.registration.organization({ utm_medium: 'dashboard-create', utm_source: 'app' })}>
          Create an organization profile
        </LinkAsButton>
        <Button kind="tertiary" onClick={onClose}>
          Not now
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AddNewMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedProduct, setSelectedProduct] = React.useState<Product | undefined>(undefined);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = React.useState(false);
  const [isCreateCompanyDialogOpen, setIsCreateCompanyDialogOpen] = React.useState(false);
  const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] = React.useState(false);
  const user = getOrUndefined(useSelfUserProfile().resource);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const isManagingACompany = getOrUndefined(useCompanies().resource)?.values.some(c =>
    c.roles.includes('company_master'),
  );
  const isManagingACommunity = getOrUndefined(useCommunities().resource)?.values.some(c =>
    c.roles.includes('company_master'),
  );
  const isManagingAnOrganization = getOrUndefined(useOrganizations().resource)?.some(o => o.isAdmin);

  const { copy } = useCopyToClipboard(
    `${config.PORTAL_URL}${userUrls.registration.user.register({ utm_source: 'share', utm_medium: 'dashboard-invite' })}`,
  );

  const notify = useNotify();

  if (isManagingACompany || isManagingACommunity || isManagingAnOrganization) {
    return null;
  }
  const open = Boolean(anchorEl);

  function closeMenu() {
    setAnchorEl(null);
  }

  function handleClick(e: React.MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const onSelectProduct = (product: Product) => {
    closeMenu();
    setSelectedProduct(product);
  };

  const onInviteClick = () => {
    if (isMobile && navigator.share) {
      closeMenu();
      navigator.share({
        title: 'Join me on CrowdWorks!',
        text: 'Join me on CrowdWorks!',
        url: `${config.PORTAL_URL}${userUrls.registration.user.register({ utm_source: 'share', utm_medium: 'dashboard-invite' })}`,
      });
    } else {
      closeMenu();
      setIsInviteDialogOpen(true);
    }
  };

  const items = [
    {
      id: 1,
      icon: <NeuralNetworkIcon />,
      primary: 'Network community',
      onClick: () => onSelectProduct('network'),
      secondary: 'Create a group for your network',
    },

    {
      id: 2,
      icon: <BriefcaseIcon />,
      primary: 'Portfolio network',
      onClick: () => onSelectProduct('portfolio'),
      secondary: 'Create a group for your portfolio of companies',
    },
    {
      id: 3,
      icon: <CalendarIcon />,
      primary: 'Event',
      onClick: () => onSelectProduct('event'),

      secondary: 'Present your event to everybody',
    },
    {
      id: 4,
      icon: <CardsIcon />,
      primary: 'Program applications',

      secondary: 'Collect applications for your accelerator program',
      onClick: () => onSelectProduct('program'),
    },
    {
      id: 5,
      icon: <HandshakeIcon />,
      primary: 'Deal flow',
      secondary: 'Collect applications for an investment deal flow',
      onClick: () => onSelectProduct('deal'),
    },
    {
      id: 6,
      icon: <UserAccountIcon />,
      primary: 'Organization profile page',
      secondary: 'Present your organization and services to everybody',
      onClick: () => {
        closeMenu();
        setIsCreateOrganizationDialogOpen(true);
      },
    },
    {
      id: 7,
      icon: <CompanyIcon />,
      primary: 'Startup profile page',
      secondary: 'Present your startup for future and existing investors',
      onClick: () => {
        closeMenu();
        setIsCreateCompanyDialogOpen(true);
      },
    },
    { id: 8, hasDivider: true, icon: <UserIcon />, primary: 'Invite people', onClick: onInviteClick },
  ];

  return (
    <>
      {isMobile ? (
        <Fab
          variant="extended"
          onClick={handleClick}
          color="primary"
          style={{ position: 'fixed', bottom: contentSpacing, right: contentSpacing }}
        >
          <PlusIcon className="u-half-spacing-right" />
          New
        </Fab>
      ) : (
        <Button kind="tertiary" className="u-flex u-flex-align-center" onClick={handleClick}>
          <IconAvatar color="blue" size={32} className="u-half-spacing-right">
            <PlusIcon />
          </IconAvatar>
          New
        </Button>
      )}
      {isMobile && (
        <DrawerBottom isOpen={open} onClose={closeMenu}>
          <h5 className="text-h5 u-content-padding-left u-content-spacing-bottom">Create</h5>
          {items.map(item => (
            <React.Fragment key={item.id}>
              {item.hasDivider && <Divider />}
              <DrawerItem prefix={item.icon} onClick={item.onClick} key={item.id} helperText={item.secondary}>
                {item.primary}
              </DrawerItem>
            </React.Fragment>
          ))}
        </DrawerBottom>
      )}

      {!isMobile && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <div className="u-content-padding-top u-quarter-padding-bottom">
            <h5 className="u-content-padding-x u-content-padding-bottom text-h5">Create</h5>
            {items.map(item => (
              <React.Fragment key={item.id}>
                {item.hasDivider && <Divider />}
                <MenuItem
                  key={item.id}
                  icon={item.icon}
                  primary={item.primary}
                  secondary={item.secondary}
                  onClick={item.onClick}
                />
              </React.Fragment>
            ))}
          </div>
        </Menu>
      )}
      {selectedProduct && (
        <SelectedProductDialog
          onClose={() => setSelectedProduct(undefined)}
          user={user}
          selectedProduct={selectedProduct}
        />
      )}
      {isInviteDialogOpen &&
        (isMobile ? (
          <DrawerBottom isOpen onClose={() => setIsInviteDialogOpen(false)}>
            <DrawerItem
              onClick={() => {
                copy();
                setIsInviteDialogOpen(false);
                notify('success', 'Link copied to clipboard');
              }}
              prefix={<LinkIcon />}
            >
              Copy invite link
            </DrawerItem>
            <a href={inviteUserMailtoString('dashboard-invite', user?.email)} target="_blank" rel="noopener noreferrer">
              <DrawerItem
                prefix={<EnvelopeIcon />}
                onClick={() => {
                  copy();
                  setIsInviteDialogOpen(false);
                }}
              >
                Invite via email
              </DrawerItem>
            </a>
          </DrawerBottom>
        ) : (
          <Dialog open onClose={() => setIsInviteDialogOpen(false)}>
            <Title onClose={() => setIsInviteDialogOpen(false)}>Invite your network</Title>
            <Content>Do you know anyone who should join, co-investors, early stage companies or colleagues?</Content>
            <DialogActions>
              <InviteUsers utm_medium="dashboard-invite" />
            </DialogActions>
          </Dialog>
        ))}
      {isCreateCompanyDialogOpen && <RegisterCompanyDialog onClose={() => setIsCreateCompanyDialogOpen(false)} />}
      {isCreateOrganizationDialogOpen && (
        <RegisterOrganizationDialog onClose={() => setIsCreateOrganizationDialogOpen(false)} />
      )}
    </>
  );
}
